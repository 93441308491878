<div class="attachment-wrapper">
  <input
    type="file"
    class="file-input"
    [accept]="requiredFileType"
    (change)="onFileSelected($event)"
    #fileUpload
  />

  <div class="file-upload" (click)="fileUpload.click()">
    {{ fileName || 'Tải lên file' }}
    <vdr-icon name="upload"></vdr-icon>
  </div>
</div>

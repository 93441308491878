<vdr-affixed-input [suffix]="suffix" [prefix]="prefix">
  <input
    type="number"
    [readonly]="readonly"
    [min]="min"
    [max]="max"
    [step]="step"
    [formControl]="formControl"
  />
</vdr-affixed-input>

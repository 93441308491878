import { gql } from 'apollo-angular'

import { ERROR_RESULT_FRAGMENT } from './shared-definitions'

export const ADDRESS_FRAGMENT = gql`
  fragment Address on Address {
    id
    createdAt
    updatedAt
    fullName
    company
    streetLine1
    streetLine2
    city
    province
    postalCode
    country {
      id
      code
      name
    }
    phoneNumber
    defaultShippingAddress
    defaultBillingAddress
  }
`

export const CUSTOMER_FRAGMENT = gql`
  fragment Customer on Customer {
    id
    createdAt
    updatedAt
    title
    firstName
    lastName
    phoneNumber
    emailAddress
    user {
      id
      identifier
      verified
      lastLogin
    }
    addresses {
      ...Address
    }
  }
  ${ADDRESS_FRAGMENT}
`

export const CUSTOMER_GROUP_FRAGMENT = gql`
  fragment CustomerGroup on CustomerGroup {
    id
    createdAt
    updatedAt
    name
  }
`

export const GET_CUSTOMER_LIST = gql`
  query GetCustomerList($options: CustomerListOptions) {
    customers(options: $options) {
      items {
        id
        createdAt
        updatedAt
        title
        firstName
        lastName
        emailAddress
        user {
          id
          verified
        }
      }
      totalItems
    }
  }
`

export const CREATE_CUSTOMER = gql`
  mutation CreateCustomer($input: CreateCustomerInput!, $password: String) {
    createCustomer(input: $input, password: $password) {
      ...Customer
      ...ErrorResult
    }
  }
  ${CUSTOMER_FRAGMENT}
  ${ERROR_RESULT_FRAGMENT}
`

export const UPDATE_CUSTOMER = gql`
  mutation UpdateCustomer($input: UpdateCustomerInput!) {
    updateCustomer(input: $input) {
      ...Customer
      ...ErrorResult
    }
  }
  ${CUSTOMER_FRAGMENT}
  ${ERROR_RESULT_FRAGMENT}
`

export const DELETE_CUSTOMER = gql`
  mutation DeleteCustomer($id: ID!) {
    deleteCustomer(id: $id) {
      result
      message
    }
  }
`

export const DELETE_CUSTOMERS = gql`
  mutation DeleteCustomers($ids: [ID!]!) {
    deleteCustomers(ids: $ids) {
      result
      message
    }
  }
`

export const CREATE_CUSTOMER_ADDRESS = gql`
  mutation CreateCustomerAddress(
    $customerId: ID!
    $input: CreateAddressInput!
  ) {
    createCustomerAddress(customerId: $customerId, input: $input) {
      ...Address
    }
  }
  ${ADDRESS_FRAGMENT}
`

export const UPDATE_CUSTOMER_ADDRESS = gql`
  mutation UpdateCustomerAddress($input: UpdateAddressInput!) {
    updateCustomerAddress(input: $input) {
      ...Address
    }
  }
  ${ADDRESS_FRAGMENT}
`

export const DELETE_CUSTOMER_ADDRESS = gql`
  mutation DeleteCustomerAddress($id: ID!) {
    deleteCustomerAddress(id: $id) {
      success
    }
  }
`

export const CREATE_CUSTOMER_GROUP = gql`
  mutation CreateCustomerGroup($input: CreateCustomerGroupInput!) {
    createCustomerGroup(input: $input) {
      ...CustomerGroup
    }
  }
  ${CUSTOMER_GROUP_FRAGMENT}
`

export const UPDATE_CUSTOMER_GROUP = gql`
  mutation UpdateCustomerGroup($input: UpdateCustomerGroupInput!) {
    updateCustomerGroup(input: $input) {
      ...CustomerGroup
    }
  }
  ${CUSTOMER_GROUP_FRAGMENT}
`

export const DELETE_CUSTOMER_GROUP = gql`
  mutation DeleteCustomerGroup($id: ID!) {
    deleteCustomerGroup(id: $id) {
      result
      message
    }
  }
`

export const DELETE_CUSTOMER_GROUPS = gql`
  mutation DeleteCustomerGroups($ids: [ID!]!) {
    deleteCustomerGroups(ids: $ids) {
      result
      message
    }
  }
`

export const GET_CUSTOMER_GROUPS = gql`
  query GetCustomerGroups($options: CustomerGroupListOptions) {
    customerGroups(options: $options) {
      items {
        ...CustomerGroup
      }
      totalItems
    }
  }
  ${CUSTOMER_GROUP_FRAGMENT}
`

export const GET_CUSTOMER_GROUP_WITH_CUSTOMERS = gql`
  query GetCustomerGroupWithCustomers($id: ID!, $options: CustomerListOptions) {
    customerGroup(id: $id) {
      ...CustomerGroup
      customers(options: $options) {
        items {
          id
          createdAt
          updatedAt
          emailAddress
          firstName
          lastName
          user {
            id
          }
        }
        totalItems
      }
    }
  }
  ${CUSTOMER_GROUP_FRAGMENT}
`

export const ADD_CUSTOMERS_TO_GROUP = gql`
  mutation AddCustomersToGroup($groupId: ID!, $customerIds: [ID!]!) {
    addCustomersToGroup(customerGroupId: $groupId, customerIds: $customerIds) {
      ...CustomerGroup
    }
  }
  ${CUSTOMER_GROUP_FRAGMENT}
`

export const REMOVE_CUSTOMERS_FROM_GROUP = gql`
  mutation RemoveCustomersFromGroup($groupId: ID!, $customerIds: [ID!]!) {
    removeCustomersFromGroup(
      customerGroupId: $groupId
      customerIds: $customerIds
    ) {
      ...CustomerGroup
    }
  }
  ${CUSTOMER_GROUP_FRAGMENT}
`

export const GET_CUSTOMER_HISTORY = gql`
  query GetCustomerHistory($id: ID!, $options: HistoryEntryListOptions) {
    customer(id: $id) {
      id
      history(options: $options) {
        totalItems
        items {
          id
          type
          createdAt
          isPublic
          administrator {
            id
            firstName
            lastName
          }
          data
        }
      }
    }
  }
`

export const ADD_NOTE_TO_CUSTOMER = gql`
  mutation AddNoteToCustomer($input: AddNoteToCustomerInput!) {
    addNoteToCustomer(input: $input) {
      id
    }
  }
`

export const UPDATE_CUSTOMER_NOTE = gql`
  mutation UpdateCustomerNote($input: UpdateCustomerNoteInput!) {
    updateCustomerNote(input: $input) {
      id
      data
      isPublic
    }
  }
`

export const DELETE_CUSTOMER_NOTE = gql`
  mutation DeleteCustomerNote($id: ID!) {
    deleteCustomerNote(id: $id) {
      result
      message
    }
  }
`

<ng-template vdrDialogTitle>
  <div class="title-row px-2 pt-2">
    {{ asset.name }}
  </div>
</ng-template>

<vdr-asset-preview
  *ngIf="assetWithTags$ | async as assetWithTags"
  [asset]="assetWithTags"
  (assetChange)="assetChanges = $event"
  (editClick)="resolveWith()"
  [assets]="assetsWithTags$ | async"
></vdr-asset-preview>

<div
  class="select-multiple-wrapper"
  [ngClass]="{ 'select-had-content': selected }"
>
  <mat-form-field>
    <mat-label>{{ label }}</mat-label>
    <mat-select [formControl]="formControl" multiple>
      @for (item of options; track item) {
        <mat-option [value]="item">{{ item }}</mat-option>
      }
    </mat-select>
  </mat-form-field>
  <img
    class="select-arrow-down"
    src="assets/icons/icn-arrow-down.svg"
    alt="icn-arrow-down"
  />
</div>

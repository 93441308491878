import { Component, Input } from '@angular/core'
import { FormControl } from '@angular/forms'

@Component({
  selector: 'vdr-search-keyword',
  templateUrl: './search-keyword.component.html',
  styleUrl: './search-keyword.component.scss'
})
export class SearchKeywordComponent {
  @Input() searchTermControl: FormControl<string>
  @Input() searchTermPlaceholder: string | undefined
}

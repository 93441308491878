import { ChangeDetectionStrategy, Component, Input } from '@angular/core'

@Component({
  selector: 'vdr-status-badge',
  templateUrl: './status-badge.component.html',
  styleUrls: ['./status-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatusBadgeComponent {
  @Input() type: 'info' | 'success' | 'warning' | 'error' = 'info'
}

import { ChangeDetectionStrategy, Component } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import {
  DefaultFormComponentConfig,
  DefaultFormComponentId
} from '@vendure/common/lib/shared-types'

import {
  FormInputComponent,
  InputComponentConfig
} from '../../../common/component-registry-types'

/**
 * @description
 * A checkbox input. The default input component for `boolean` fields.
 *
 * @docsCategory custom-input-components
 * @docsPage default-inputs
 */
@Component({
  selector: 'vdr-boolean-form-input',
  templateUrl: './boolean-form-input.component.html',
  styleUrls: ['./boolean-form-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BooleanFormInputComponent implements FormInputComponent {
  static readonly id: DefaultFormComponentId = 'boolean-form-input'
  readonly: boolean
  formControl: UntypedFormControl
  config: DefaultFormComponentConfig<'boolean-form-input'>
}

import { gql } from 'apollo-angular'

export const FACET_VALUE_FRAGMENT = gql`
  fragment FacetValue on FacetValue {
    id
    createdAt
    updatedAt
    languageCode
    code
    name
    translations {
      id
      languageCode
      name
    }
    facet {
      id
      createdAt
      updatedAt
      name
    }
  }
`

export const FACET_WITH_VALUES_FRAGMENT = gql`
  fragment FacetWithValues on Facet {
    id
    createdAt
    updatedAt
    languageCode
    isPrivate
    code
    name
    translations {
      id
      languageCode
      name
    }
    values {
      ...FacetValue
    }
  }
  ${FACET_VALUE_FRAGMENT}
`

export const FACET_WITH_VALUE_LIST_FRAGMENT = gql`
  fragment FacetWithValueList on Facet {
    id
    createdAt
    updatedAt
    languageCode
    isPrivate
    code
    name
    translations {
      id
      languageCode
      name
    }
    valueList(options: $facetValueListOptions) {
      totalItems
      items {
        ...FacetValue
      }
    }
  }
  ${FACET_VALUE_FRAGMENT}
`

export const CREATE_FACET = gql`
  mutation CreateFacet($input: CreateFacetInput!) {
    createFacet(input: $input) {
      ...FacetWithValues
    }
  }
  ${FACET_WITH_VALUES_FRAGMENT}
`

export const UPDATE_FACET = gql`
  mutation UpdateFacet($input: UpdateFacetInput!) {
    updateFacet(input: $input) {
      ...FacetWithValues
    }
  }
  ${FACET_WITH_VALUES_FRAGMENT}
`

export const DELETE_FACET = gql`
  mutation DeleteFacet($id: ID!, $force: Boolean) {
    deleteFacet(id: $id, force: $force) {
      result
      message
    }
  }
`

export const DELETE_FACETS = gql`
  mutation DeleteFacets($ids: [ID!]!, $force: Boolean) {
    deleteFacets(ids: $ids, force: $force) {
      result
      message
    }
  }
`

export const CREATE_FACET_VALUES = gql`
  mutation CreateFacetValues($input: [CreateFacetValueInput!]!) {
    createFacetValues(input: $input) {
      ...FacetValue
    }
  }
  ${FACET_VALUE_FRAGMENT}
`

export const UPDATE_FACET_VALUES = gql`
  mutation UpdateFacetValues($input: [UpdateFacetValueInput!]!) {
    updateFacetValues(input: $input) {
      ...FacetValue
    }
  }
  ${FACET_VALUE_FRAGMENT}
`

export const DELETE_FACET_VALUES = gql`
  mutation DeleteFacetValues($ids: [ID!]!, $force: Boolean) {
    deleteFacetValues(ids: $ids, force: $force) {
      result
      message
    }
  }
`

export const GET_FACET_VALUE_LIST = gql`
  query GetFacetValueList($options: FacetValueListOptions) {
    facetValues(options: $options) {
      items {
        ...FacetValue
      }
      totalItems
    }
  }
  ${FACET_VALUE_FRAGMENT}
`

export const ASSIGN_FACETS_TO_CHANNEL = gql`
  mutation AssignFacetsToChannel($input: AssignFacetsToChannelInput!) {
    assignFacetsToChannel(input: $input) {
      id
    }
  }
`

export const REMOVE_FACETS_FROM_CHANNEL = gql`
  mutation RemoveFacetsFromChannel($input: RemoveFacetsFromChannelInput!) {
    removeFacetsFromChannel(input: $input) {
      ... on Facet {
        id
      }
      ... on FacetInUseError {
        errorCode
        message
        variantCount
        productCount
      }
    }
  }
`

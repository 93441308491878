import { Component, EventEmitter, Input, Output } from '@angular/core'
import { Subscription } from 'rxjs'

@Component({
  selector: 'vdr-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrl: './file-upload.component.scss'
})
export class FileUploadComponent {
  @Input()
  requiredFileType: string

  fileName = ''
  uploadProgress: number
  uploadSub: Subscription
  @Output() fileSelected = new EventEmitter<File>()

  onFileSelected(event) {
    const file: File = event.target.files[0]

    if (file) {
      this.fileName = file.name
      const formData = new FormData()
      formData.append('thumbnail', file)
      this.fileSelected.emit(file)
    }
  }
}

import { gql } from 'apollo-angular'

import {
  CONFIGURABLE_OPERATION_DEF_FRAGMENT,
  CONFIGURABLE_OPERATION_FRAGMENT
} from './shared-definitions'

export const SHIPPING_METHOD_FRAGMENT = gql`
  fragment ShippingMethod on ShippingMethod {
    id
    createdAt
    updatedAt
    code
    name
    description
    fulfillmentHandlerCode
    checker {
      ...ConfigurableOperation
    }
    calculator {
      ...ConfigurableOperation
    }
    translations {
      id
      languageCode
      name
      description
    }
  }
  ${CONFIGURABLE_OPERATION_FRAGMENT}
`

export const GET_SHIPPING_METHOD_OPERATIONS = gql`
  query GetShippingMethodOperations {
    shippingEligibilityCheckers {
      ...ConfigurableOperationDef
    }
    shippingCalculators {
      ...ConfigurableOperationDef
    }
    fulfillmentHandlers {
      ...ConfigurableOperationDef
    }
  }
  ${CONFIGURABLE_OPERATION_DEF_FRAGMENT}
`

export const CREATE_SHIPPING_METHOD = gql`
  mutation CreateShippingMethod($input: CreateShippingMethodInput!) {
    createShippingMethod(input: $input) {
      ...ShippingMethod
    }
  }
  ${SHIPPING_METHOD_FRAGMENT}
`

export const UPDATE_SHIPPING_METHOD = gql`
  mutation UpdateShippingMethod($input: UpdateShippingMethodInput!) {
    updateShippingMethod(input: $input) {
      ...ShippingMethod
    }
  }
  ${SHIPPING_METHOD_FRAGMENT}
`

export const DELETE_SHIPPING_METHOD = gql`
  mutation DeleteShippingMethod($id: ID!) {
    deleteShippingMethod(id: $id) {
      result
      message
    }
  }
`

export const DELETE_SHIPPING_METHODS = gql`
  mutation DeleteShippingMethods($ids: [ID!]!) {
    deleteShippingMethods(ids: $ids) {
      result
      message
    }
  }
`

export const TEST_SHIPPING_METHOD = gql`
  query TestShippingMethod($input: TestShippingMethodInput!) {
    testShippingMethod(input: $input) {
      eligible
      quote {
        price
        priceWithTax
        metadata
      }
    }
  }
`

export const TEST_ELIGIBLE_SHIPPING_METHODS = gql`
  query TestEligibleShippingMethods($input: TestEligibleShippingMethodsInput!) {
    testEligibleShippingMethods(input: $input) {
      id
      name
      code
      description
      price
      priceWithTax
      metadata
    }
  }
`

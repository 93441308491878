<ng-container *ngIf="!renderAsList; else list">
  <ng-container #single></ng-container>
</ng-container>
<ng-template #list>
  <div
    class="list-container"
    cdkDropList
    (cdkDropListDropped)="moveListItem($event)"
  >
    <div
      class="list-item-row"
      *ngFor="let item of listItems; trackBy: trackById"
      cdkDrag
      [cdkDragData]="item"
      [cdkDragLockAxis]="'y'"
    >
      <div class="flex-spacer pr-2">
        <ng-container #listItem></ng-container>
      </div>
      <button
        class="button-small"
        *ngIf="!readonly"
        (click)="removeListItem(item)"
        [title]="'common.remove-item-from-list' | translate"
      >
        <clr-icon shape="times"></clr-icon>
      </button>
      <div class="drag-handle" cdkDragHandle [class.hidden]="readonly">
        <clr-icon shape="drag-handle" size="24"></clr-icon>
      </div>
    </div>
    <button
      class="btn btn-secondary btn-sm"
      (click)="addListItem()"
      *ngIf="!readonly"
    >
      <clr-icon shape="plus"></clr-icon>
      {{ 'common.add-item-to-list' | translate }}
    </button>
  </div>
</ng-template>

<ng-template vdrDialogTitle>
  <div class="dialog-header">
    <div class="dialog-title">
      {{ title | translate: translationVars }}
    </div>
    <div class="btn-close" (click)="resolveWith()">
      <vdr-icon name="close"></vdr-icon>
    </div>
  </div>
</ng-template>
<div class="dialog-content" style="white-space: pre-wrap">
  <div *ngIf="confirmDelete" class="icn">
    <vdr-icon name="trash"></vdr-icon>
  </div>
  {{ body | translate: translationVars }}
</div>
<ng-template vdrDialogButtons>
  <div class="dialog-footer">
    <ng-container *ngFor="let button of buttons">
      <button
        class="btn"
        [ngClass]="{
          'btn-primary': button.type === 'primary',
          'btn-danger': button.type === 'danger',
          'btn-secondary': button.type === 'secondary'
        }"
        (click)="resolveWith(button.returnValue)"
      >
        {{ button.label | translate: button.translationVars || {} }}
      </button>
    </ng-container>
  </div>
</ng-template>

import { gql } from 'apollo-angular'

export const ROLE_FRAGMENT = gql`
  fragment Role on Role {
    id
    createdAt
    updatedAt
    code
    description
    permissions
    channels {
      id
      code
      token
    }
  }
`

export const ADMINISTRATOR_FRAGMENT = gql`
  fragment Administrator on Administrator {
    id
    createdAt
    updatedAt
    firstName
    lastName
    emailAddress
    user {
      id
      identifier
      lastLogin
      roles {
        ...Role
      }
    }
  }
  ${ROLE_FRAGMENT}
`

export const GET_ACTIVE_ADMINISTRATOR = gql`
  query GetActiveAdministrator {
    activeAdministrator {
      ...Administrator
    }
  }
  ${ADMINISTRATOR_FRAGMENT}
`

export const CREATE_ADMINISTRATOR = gql`
  mutation CreateAdministrator($input: CreateAdministratorInput!) {
    createAdministrator(input: $input) {
      ...Administrator
    }
  }
  ${ADMINISTRATOR_FRAGMENT}
`

export const UPDATE_ADMINISTRATOR = gql`
  mutation UpdateAdministrator($input: UpdateAdministratorInput!) {
    updateAdministrator(input: $input) {
      ...Administrator
    }
  }
  ${ADMINISTRATOR_FRAGMENT}
`

export const UPDATE_ACTIVE_ADMINISTRATOR = gql`
  mutation UpdateActiveAdministrator($input: UpdateActiveAdministratorInput!) {
    updateActiveAdministrator(input: $input) {
      ...Administrator
    }
  }
  ${ADMINISTRATOR_FRAGMENT}
`

export const DELETE_ADMINISTRATOR = gql`
  mutation DeleteAdministrator($id: ID!) {
    deleteAdministrator(id: $id) {
      result
      message
    }
  }
`

export const DELETE_ADMINISTRATORS = gql`
  mutation DeleteAdministrators($ids: [ID!]!) {
    deleteAdministrators(ids: $ids) {
      result
      message
    }
  }
`

export const GET_ROLES = gql`
  query GetRoles($options: RoleListOptions) {
    roles(options: $options) {
      items {
        ...Role
      }
      totalItems
    }
  }
  ${ROLE_FRAGMENT}
`

export const CREATE_ROLE = gql`
  mutation CreateRole($input: CreateRoleInput!) {
    createRole(input: $input) {
      ...Role
    }
  }
  ${ROLE_FRAGMENT}
`

export const UPDATE_ROLE = gql`
  mutation UpdateRole($input: UpdateRoleInput!) {
    updateRole(input: $input) {
      ...Role
    }
  }
  ${ROLE_FRAGMENT}
`

export const DELETE_ROLE = gql`
  mutation DeleteRole($id: ID!) {
    deleteRole(id: $id) {
      result
      message
    }
  }
`

export const DELETE_ROLES = gql`
  mutation DeleteRoles($ids: [ID!]!) {
    deleteRoles(ids: $ids) {
      result
      message
    }
  }
`

export const ASSIGN_ROLE_TO_ADMINISTRATOR = gql`
  mutation AssignRoleToAdministrator($administratorId: ID!, $roleId: ID!) {
    assignRoleToAdministrator(
      administratorId: $administratorId
      roleId: $roleId
    ) {
      ...Administrator
    }
  }
  ${ADMINISTRATOR_FRAGMENT}
`

<div class="auth-wrapper">
  <div class="auth-wrapper-inner">
    <div class="auth-wrapper-image">
      <div class="auth-wrapper-image-content">
        <div class="auth-wrapper-image-content-inner">
          <div class="auth-wraper-logo">
            <img src="assets/auth/logo.png" />
          </div>
          <!-- <div class="auth-wrapper-image-title">
            {{ "common.auth-image-title" | translate }}
          </div> -->
        </div>
      </div>
      <img class="auth-background" src="assets/auth/bg.png" />
    </div>
    <div class="auth-wrapper-form">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

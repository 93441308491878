<nav class="main-nav">
  <section class="">
    <ng-container *ngFor="let section of mainMenuConfig$ | async">
      <section
        class="nav-group"
        [attr.data-section-id]="section.id"
        [class.collapsible]="section.collapsible"
        [class.collapsed]="
          section.collapsible && !expandedSections.includes(section.id)
        "
        routerLinkActive="active"
        (isActiveChange)="setExpanded(section, $event)"
        *ngIf="shouldDisplayLink(section)"
      >
        <vdr-ui-extension-point
          [locationId]="section.id"
          api="navMenu"
          [topPx]="0"
          [leftPx]="8"
          display="block"
        >
          <div class="section-header">
            <ng-container
              *ngIf="
                navBuilderService.sectionBadges[section.id]
                  | async as sectionBadge
              "
            >
              <vdr-status-badge
                *ngIf="sectionBadge !== 'none'"
                [type]="sectionBadge"
              ></vdr-status-badge>
            </ng-container>
            <label
              [ngClass]="{ 'nav-group-collapse': section.collapsible }"
              *ngIf="section.label"
              class="nav-group-header mx-3"
              [for]="section.id"
              (click)="section.collapsible && toggleExpand(section)"
            >
              <vdr-icon *ngIf="section.icon" [name]="section.icon"></vdr-icon>
              <span>{{ section.label | translate }}</span>
            </label>
            <div
              *ngIf="section.collapsible"
              class="btn-expand"
              [ngClass]="
                expandedSections.includes(section.id) && 'active-group-icon'
              "
              (click)="toggleExpand(section)"
            >
              <img
                class="arrow-down"
                src="assets/icons/icn-chevron-down.svg"
                alt="icn-chevron-down"
              />
            </div>
          </div>
          <div class="nav-list" [ngStyle]="getStyleForSection(section)">
            <ng-container *ngFor="let item of section.items">
              <div
                *ngIf="shouldDisplayLink(item)"
                class="nav-link px-3"
                [ngClass]="{
                  only: section.items.length <= 1,
                  disabled: item.disabled
                }"
                routerLinkActive="active"
              >
                <a
                  [attr.data-item-id]="section.id"
                  [routerLink]="getRouterLink(item)"
                  (click)="onItemClick(item, $event)"
                >
                  <ng-container *ngIf="item.statusBadge | async as itemBadge">
                    <vdr-status-badge
                      *ngIf="itemBadge.type !== 'none'"
                      [type]="itemBadge.type"
                    ></vdr-status-badge>
                  </ng-container>
                  <vdr-icon *ngIf="item.icon" [name]="item.icon"></vdr-icon>
                  <span>{{ item.label | translate }}</span>
                </a>
              </div>
            </ng-container>
          </div>
        </vdr-ui-extension-point>
      </section>
    </ng-container>
  </section>
</nav>

<div
  class="toggle"
  [class.hide-when-off]="hiddenWhenOff"
  [class.disabled]="disabled"
  [class.small]="size === 'small'"
  [attr.tabindex]="disabled ? null : 0"
  [class.selected]="selected"
  (keydown.enter)="selectedChange.emit(!selected)"
  (keydown.space)="$event.preventDefault(); selectedChange.emit(!selected)"
  (click)="selectedChange.emit(!selected)"
>
  <div class="toggle-inner">
    <clr-icon
      class="clr-icon"
      shape="check"
      [class.is-solid]="selected"
      [attr.size]="size === 'small' ? 18 : 24"
    ></clr-icon>
  </div>
</div>
<!-- <div
    class="toggle-label"
    [class.selected]="selected"
    [class.disabled]="disabled"
    *ngIf="label"
    (click)="selectedChange.emit(!selected)"
>
    {{ label }}
</div> -->

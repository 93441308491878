import { Component, Input, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms'

@Component({
  selector: 'vdr-select-multiple',
  templateUrl: './select-multiple.component.html',
  styleUrl: './select-multiple.component.scss'
})
export class SelectMultipleComponent implements OnInit {
  @Input() options = []
  @Input() formControl: FormControl<string[]>
  @Input() label: string | undefined
  @Input() readonly: boolean | undefined
  selected: boolean

  ngOnInit() {
    this.formControl.valueChanges.subscribe((values) => {
      this.selected = values.length > 0
    })
  }
}

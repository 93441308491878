import { Component, Input } from '@angular/core'

@Component({
  selector: 'vdr-icon',
  templateUrl: './icon.component.html',
  styleUrl: './icon.component.scss'
})
export class IconComponent {
  @Input() name:
    | 'home-smile'
    | 'building'
    | 'plate'
    | 'clipboard'
    | 'chart'
    | 'users-group'
    | 'pie-chart'
    | 'dialog'
    | 'ticket-sale'
    | 'speaker'
    | 'sort'
    | 'dollar'
    | 'settings'
    | 'document'
    | 'close'
    | 'trash'
    | 'upload'
    | 'chevron-right'
    | 'map'
    | 'pie-chart-2'
    | 'keyword'
}

<div class="items-per-page-wrapper">
  <vdr-dropdown>
    <button class="button" vdrDropdownTrigger>
      <span>{{
        'common.items-per-page-option' | translate: { count: itemsPerPage }
      }}</span>
      <img
        class="arrow-down"
        src="assets/icons/icn-arrow-down.svg"
        alt="icn-arrow-down"
      />
    </button>
    <vdr-dropdown-menu vdrPosition="bottom-left">
      <button vdrDropdownItem (click)="itemsPerPageChange.emit(10)">
        {{ 'common.items-per-page-option' | translate: { count: 10 } }}
      </button>
      <button vdrDropdownItem (click)="itemsPerPageChange.emit(25)">
        {{ 'common.items-per-page-option' | translate: { count: 25 } }}
      </button>
      <button vdrDropdownItem (click)="itemsPerPageChange.emit(50)">
        {{ 'common.items-per-page-option' | translate: { count: 50 } }}
      </button>
      <button vdrDropdownItem (click)="itemsPerPageChange.emit(100)">
        {{ 'common.items-per-page-option' | translate: { count: 100 } }}
      </button>
    </vdr-dropdown-menu>
  </vdr-dropdown>
</div>

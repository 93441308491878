<ng-template #vdrDt2Search>
  <div class="search-wrapper">
    <!-- <clr-icon shape="search" class="search-icon"></clr-icon> -->
    <input
      class="input primary"
      [formControl]="searchTermControl"
      [placeholder]="searchTermPlaceholder"
    />
  </div>
</ng-template>
